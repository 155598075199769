import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer';
import { ContactButton } from '../../../Styled/CustomElements'
import { Box, HeadingStyled, TextStyled } from '../../../Styled/Elements'
import { Rocket, RocketLeft, RocketRight, RocketSmoke, WhatsappBtn } from '../../../Styled/AllImages'


const AboutCard = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  const { t } = useTranslation()
  const WhatsApp = () => {
    const phoneNumber = '+359877129504';
    const message = 'Hello, Quantum Digtal';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
};
  return (
    <AboutCardWapper className='container my-5'>
      <Container>
        <Row className='card'>
          <Col>
            <Box className='card-content-wrap'>
              <HeadingStyled className='heading-text-response' color='var(--white)'>{t('Infusing_Social_Brilliance')}</HeadingStyled>
              <HeadingStyled className='sub-heading-text-response' size='24px' color='var(--white)'>{t('Ready_boost')}</HeadingStyled>
              <TextStyled color='var(--white)'>{t('Contact_today')}</TextStyled>
              <ContactButton className='card-btn' icon={WhatsappBtn} label={t('Enguire_Now')} onClick={WhatsApp} />
              <Box className={`rocket-animation ${isVisible ? 'animation' : ''}`} ref={ref}>
                <span className='about-card-img '>
                  <img className='rocket rocket-left' src={RocketLeft} alt='img' />
                  <img className='rocket rocket-right' src={RocketRight} alt='img' />
                  <img className='rocket rocket-jet' src={Rocket} alt='img' />
                  <img className='rocket rocket-smoke' src={RocketSmoke} alt='img' />
                </span>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </AboutCardWapper>
  )
}
const AboutCardWapper = styled.section`
.card{
    position: relative;
}
.rocket-animation{
    position: absolute;
    width: 30%;
    right: 0px;
    top: 20px;
}
.animation .rocket-jet{
    animation: rocket-jet 3s ease  ;

}
@keyframes rocket-jet {
    0% {
      opacity: 1;
    transform: translateY(0);
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-300px);
    }
  }
.animation .rocket-smoke{
    animation: rocket-smoke 3s ease ;

}
@keyframes rocket-smoke {
    0% {
      opacity: 1;
    transform: translateY(0);
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translateY(5px);
    }
  }
.animation .rocket-right{
    animation: rocket-right 3s ease ;

}
@keyframes rocket-right {
    0% {
      opacity: 1;
      right: 0;
      top: 0;
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      right: -120px;
      top: -70px;
    }
  }
.animation .rocket-left{
    animation: rocket-left 3s ease ;

}
@keyframes rocket-left {
    0% {
      opacity: 1;
      left: 0;
      top: 0;
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      left: -120px;
      top: -70px;
    }
  }
.about-card-img {
    position: relative;
    display: block;
    width:100%;
    height:100%;
}
  .rocket{
    position: absolute;
  }
`

export default AboutCard
