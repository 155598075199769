import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from '../../../Styled/Elements'
import { AboutImg, WhatsappBtn } from '../../../Styled/AllImages'
import { ContactButton } from '../../../Styled/CustomElements'
import AboutCard from './AboutCard'
import { useTranslation } from 'react-i18next'
import { } from '../../../Styled/AllImages'

const About = () => {

    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+359877129504';
        const message = 'Hello, Quantum Digtal';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
            <AboutWapper className='container-fluid about-bg common-space' id='about'>
                <Container>
                    <Row className='about-text-card'>
                        <Col lg={6} md={12} className='about-img'>
                            <LazyImage src={AboutImg} />
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='position-relative about-content-wrap'>
                                <SpanStyled lh='5px' className='subHeading' color='var(--themeColor)' size='18px' weight='bold'>{t('Who_we_are')}</SpanStyled>
                                <HeadingStyled lh='50px' className='themeTextGradient'>{t('Quantum_Digital')}</HeadingStyled>
                                <HeadingStyled lh='1' className='heading-text-response' color='var(--themeColor)'>{t('In_Nutshell')}</HeadingStyled>
                                <Box className='py-3'>
                                    <TextStyled>{t('Welcome_Quantum')}</TextStyled>
                                    <TextStyled>{t('Quantum_Digtal')}</TextStyled>
                                </Box>
                                <ContactButton className='btn-gradient-bg' icon={WhatsappBtn} label={t('Enguire_Now')} onClick={WhatsApp} />
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </AboutWapper>
            <AboutCard />
        </>
    )
}
const AboutWapper = styled.section`
.about-text-card{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .about-content-wrap{
        display:flex;
        flex-direction:column;
    }
    .about-img{
         span{
            width:100%;
        }
         img{
            border-radius:20px;
            width:100%;
        }
    }  
}
`
export default About
